import { Button, message, Modal, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "../Footer.module.sass";
import { updateMe } from "../../../api/me";
import { useAppStateStore } from "../../../store/appState";
import { useNameCardFromValidate } from "../../../context/NameCardFromValidate";
import { useEffect, useState } from "react";
import { memberCheck, sendAuthCode } from "../../../api";
import isMobileNumberChange from "../../../utils/isMobileNumberChange";
// import { setTimeout } from "timers/promises";

const ProfileFooter = () => {
  const navigate = useNavigate();
  const { USER, PROFILE_PAGE, APP, BRAND } = useAppStateStore();
  const { setAppState } = APP;
  const { setUserState, userState } = USER;
  const { brandState } = BRAND; // 24.11.30 SCH ★
  const { auth_code_length } = brandState; // 24.11.30 SCH ★
  const { accessToken, role, mobile_number } = userState;

  const { setProfileInputData, profileInputData, initProfileInputData } =
    PROFILE_PAGE;
  const {
    isValidateEmail,
    isValidateName,
    isValidatePhoneNumber,
    isValidateBankCode,
    isValidateBankAccount,
    bank_code,
    bank_account,
    mobile_number: profileMobileNumber,
  } = profileInputData;
  const { setMobileNumber, setIsValidateAll } = useNameCardFromValidate(); // 24.11.07 SCH ★
  const { setRealName, setEmailUrl, setPromoSlug } = useNameCardFromValidate();
  const [isModalAuthMobileNumberOpen, setIsModalAuthMobileNumberOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [isAuthMobileNumber, setIsAuthMobileNumber] = useState(false);
  // const [timesCount, setTimesCount] = useState(0); // 24.12.02 SCH ★

  // 手機號碼是否變更
  // const isMobileNumberChange = () => {
  //   let profileMobileNumberTrim = profileMobileNumber?.trim();
  //   console.log("profileMobileNumberTrim", profileMobileNumberTrim);
  //   console.log("profileMobileNumber", profileMobileNumber);
  //   console.log("mobile_number", mobile_number); // 24.12.01 SCH ★
  //   return (
  //     // profileMobileNumber 代表的是 input 的值，mobile_number 代表的是資料庫的值
  //     // profileMobileNumber 只有在符合手機號碼格式的條件下才會把值存到 profileMobileNumber 這個狀態中
  //     // 所以當 mobile_number 和 profileMobileNumber 不相等時，代表手機號碼有變更
  //     profileMobileNumber &&
  //     profileMobileNumberTrim !== "" &&
  //     profileMobileNumberTrim !== mobile_number
  //   );
  // };

  const updateMeData = async () => {
    try {
      setAppState({ isLoading: true });
      if (accessToken && profileInputData) {
        console.log("profileInputData Footer", profileInputData);
        try {
          const { data: res, error: meError } = await updateMe(
            accessToken,
            profileInputData
          );
          if (res) {
            const { data } = res;
            if (res) {
              const { data: updateMeResData } = data;
              setUserState({ ...updateMeResData, user_id: updateMeResData.id });
            }
          }
          setAppState({ isLoading: false });
          // initProfileInputData(); // 24.11.06 SCH ★

          // 24.11.06 By RichardSu ★
          if (!meError) {
            navigate(`/`); // 24.11.06 SCH ★
            initProfileInputData(); // 24.11.06 SCH ★
          } else {
            console.log("updateMe", meError);
            const keepData = {
              mobile_number: profileInputData.mobile_number,
              email: profileInputData.email,
              realname: profileInputData.realname,
              promo_slug: profileInputData.promo_slug,
              isValidateAll: false,
            };
            console.log("updateMe.keepData", keepData);
            setRealName(profileInputData.realname ?? "");
            setMobileNumber(profileInputData.mobile_number ?? "");
            setEmailUrl(profileInputData.email ?? ""); // 24.11.07 SCH ★★★
            setPromoSlug(profileInputData.promo_slug ?? "");

            if (meError.response?.status === 422) {
              const { message: errorMsg } = meError.response
                .data as unknown as { message: string };
              if (errorMsg === "The mobile number has already been taken.") {
                console.log("updateMe.422", errorMsg);
                setProfileInputData({
                  mobile_number: profileInputData.mobile_number,
                }); // 24.11.07 SCH ★
                setIsValidateAll(false);
                console.log("profileInputData Footer-1", profileInputData);
                return message.warn("儲存失敗，手機號碼已被他人使用", 5); // 24.11.06 SCH ★
              }
            }
            if (meError.response?.status === 403) {
              const { message: errorMsg } = meError.response
                .data as unknown as { message: string };
              if (errorMsg === "The promo_slug has existed.") {
                console.log("updateMe.403", errorMsg);
                setProfileInputData({
                  promo_slug: profileInputData.promo_slug,
                }); // 24.11.07 SCH ★
                console.log("profileInputData Footer-2", profileInputData);
                setIsValidateAll(false);
                return message.warn("（自訂）連結推廣碼已被他人佔用", 5); // 24.11.06 SCH ★
              }
              if (errorMsg === "The promo_slug can not contains blank.") {
                console.log("updateMe.422", errorMsg);
                setProfileInputData({
                  promo_slug: profileInputData.promo_slug,
                }); // 24.11.18 SCH ★
                console.log("profileInputData Footer-3", profileInputData);
                setIsValidateAll(false);
                return message.warn("（自訂）連結推廣碼不可包含空白", 5); // 24.12.04 SCH ★
              }
            }
          }
        } catch (err422) {
          console.log("updateMe Error 422 -", err422); // 24.11.06 SCH ★
        }
      }
    } catch (error) {
      setAppState({
        isLoading: false,
        isError: true,
        errorMsg: `updateMeData Error -, ${error}`,
      });
      console.log("updateMeData Error -", error);
    }
  };

  /**
   * get auth code
   * @param values
   */
  const getAuthCode = async (mobileNumber: string) => {
    if (!accessToken || !mobileNumber) return;
    try {
      const { data, error } = await sendAuthCode(
        accessToken,
        brandState.id,
        mobileNumber,
        "M"
      );
      if (data && !error) {
        console.log("data", data); // 24.12.17 SCH ★
        // data [0908715876-2024-12-17-14-53-52]
        // msgid=1390139877
        // statuscode=1 // 因有三竹簡訊 response 四行資料插進來, 造成無法正常取出auth_lead"的值(value)
        // AccountPoint=288
        // {"success":true,"message":"send auth code successfully","auth_code":null,"auth_lead":"IP-FLOW-AUTH-CODE:"}
        const { auth_lead } = data;
        console.log("auth_lead", auth_lead);
        if (auth_lead === undefined) {
          message.info("IP-FLOW-AUTH-CODE:", 7);
        } else {
          message.info(auth_lead, 7);
        }
        return message.success("驗證碼已發送");
      }

      if (error) {
        setLoading(false);
        setCountdown(0);
        const { response } = error;
        if (response) {
          const { data: errorRes } = response;
          const { message: errorMsg } = errorRes;
          if (response.status === 422) {
            if (errorMsg === "The given data was invalid.") {
              return message.error("送出的資料無效，請檢查輸入內容");
            }
          }
          if (response.status === 403) {
            if (JSON.stringify(errorRes).includes("send auth code failed.")) {
              return message.error("發送驗證碼失敗");
            }
          }
        } else {
          return message.error("發生錯誤，請重新操作");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetCaptcha = async () => {
    if (!profileInputData.mobile_number) return;
    setLoading(true);
    setCountdown(300); // 預設是 300 秒倒數
    getAuthCode(profileInputData.mobile_number);
  };

  /**
   * member check api
   */
  const memberCheckApi = async () => {
    // console.log("accessToken", accessToken); // 24.12.02 SCH ★
    // console.log(
    //   "profileInputData.mobile_number",
    //   profileInputData.mobile_number
    // ); // 24.12.02 SCH ★
    // console.log("profileInputData.realname", profileInputData.realname);
    // console.log("authCode", authCode); // 24.12.02 SCH ★

    // 當使用者資料庫中沒有 realname 時，使用 profileInputData 的 realname
    // 因為 profile page 的 realname 是從 input 中來的，並且有經過 isValidateName 的檢查才會寫入
    // 而儲存按鈕是會在 isValidateName 檢查通過後才可以被點擊
    const currentRealName =
      userState.realname === ""
        ? profileInputData.realname
        : userState.realname;
    const currentEmail =
      userState.email === "" ? profileInputData.email : userState.email;
    try {
      if (
        !accessToken ||
        !profileInputData.mobile_number ||
        !currentRealName ||
        !authCode
      )
        return;
      const { data, error } = await memberCheck(
        accessToken,
        profileInputData.mobile_number,
        currentRealName,
        currentEmail,
        authCode
      );
      console.log("data", data);
      if (data && !error) {
        setIsModalAuthMobileNumberOpen(false);
        setIsAuthMobileNumber(true);
        message.info("個人資料已儲存", 3); // 24.12.04 SCH ★
        setTimeout(() => {
          window.location.reload(); // 24.12.03 SCH ★
        }, 3000);
        return message.success("驗證成功");
      }

      if (error) {
        const { response } = error;
        if (response) {
          const { data: errorRes } = response;
          const { message: errorMsg } = errorRes;
          if (errorMsg === "authentication failed!") {
            return message.error("驗證碼錯誤，請重新輸入");
          } else {
            return message.error("發生錯誤，請重新操作");
          }
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      setLoading(false);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => {
            initProfileInputData();
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          disabled={
            !(isValidateEmail && isValidateName && isValidatePhoneNumber) ||
            isAuthMobileNumber || // 24.12.03 SCH ★ (儲存之前可能會再修改)
            (role >= 2 &&
              ((bank_code?.trim() !== "" && !isValidateBankCode) ||
                (bank_account?.trim() !== "" && !isValidateBankAccount))) // 當 role >= 2 是付費會員時，有填 bank_code 和 bank_account 才會檢查
          }
          onClick={() => {
            // profileMobileNumber 代表的是 input 的值，mobile_number 代表的是資料庫的值
            // profileMobileNumber 只有在符合手機號碼格式的條件下才會把值存到 profileMobileNumber 這個狀態中
            // 否則會是 undefined
            // 所以當 profileMobileNumber 和 mobile_number 不相等時，代表手機號碼有變更
            const isMobileNumberChanged = isMobileNumberChange(
              profileMobileNumber,
              mobile_number
            ); // 24.12.01 SCH ★
            // let newCount = timesCount + 1; // 24.12.02 SCH ★
            // setTimesCount(newCount); // 24.12.02 SCH ★
            // console.log("timesCount", newCount); // 24.12.02 SCH ★
            // console.log("isMobileNumberChanged", isMobileNumberChanged);
            // console.log(
            //   "isModalAuthMobileNumberOpen",
            //   isModalAuthMobileNumberOpen
            // );
            // if (isMobileNumberChanged && newCount <= 1) {
            //   setIsModalAuthMobileNumberOpen(true);
            //   setIsAuthMobileNumber(false);
            //   return;
            // }

            // 如果手機號碼有變更，且還沒有驗證過手機號碼，則開啟驗證手機號碼的 modal
            // isAuthMobileNumber 預設為 false，只有在通過 memberCheckApi 驗證成功後，才會變成 true
            if (isMobileNumberChanged && !isAuthMobileNumber) {
              setIsModalAuthMobileNumberOpen(true);
              return;
            }
            updateMeData();
            // navigate(`/`); // 24.11.06 SCH ★
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
      <Modal
        title="驗證手機號碼"
        open={isModalAuthMobileNumberOpen}
        onOk={() => setIsModalAuthMobileNumberOpen(false)}
        footer={null}
        width={300}
        onCancel={() => setIsModalAuthMobileNumberOpen(false)}
      >
        <Form onFinish={memberCheckApi} autoComplete="off">
          <Form.Item
            className={classes.memberCheckFormItem}
            name="authCode"
            rules={[
              { required: true, message: "請輸入驗證碼" },
              {
                max: auth_code_length, // 24.12.31 SCH ★
                // eslint-disable-next-line no-useless-concat
                message: "驗證碼最多只能 " + `${auth_code_length}` + " 個字元",
              },
            ]}
          >
            <div className={classes.authCodeInputWrapper}>
              <div className={classes.authCodeInput}>
                <Input
                  maxLength={3}
                  placeholder="請輸入驗證碼"
                  onChange={(e) => setAuthCode(e.target.value)}
                />
              </div>
              <div className={classes.authCodeButton}>
                <Button
                  onClick={handleGetCaptcha}
                  loading={loading}
                  disabled={countdown > 0}
                >
                  {countdown > 0 ? `${countdown} 秒` : "獲取驗證碼"}
                </Button>
              </div>
            </div>
          </Form.Item>
          <div className={classes.authCodeButton}>
            <Button type="primary" htmlType="submit">
              送出
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ProfileFooter;
